import { useState } from 'react';
import { Row, Spinner } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

// Custom Component
import { TournamentCard } from "../Card";
import NoTournament from "./NoTournament";

const MyTournament = (props) => {
    const [pageNumber, setPageNumber] = useState(1);

    /**
         * @function fetchMoreTournament
         * @params
         * @description fetch more tournament
         */
    const fetchMoreTournament = () => { 
        const nextPage = pageNumber + 1;
        setPageNumber(nextPage);
        props?.fetchTournamentList(nextPage)
    }

    return <div className='my-3'>
        {props?.myTournamentList?.length > 0 ?
            <InfiniteScroll
                className='overflow-hidden'
                dataLength={props?.myTournamentList?.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)?.length}
                next={fetchMoreTournament}
                hasMore={props?.pageLoad}
                loader={<h4 className='text-center'>
                    <Spinner animation="border" />
                </h4>}
                endMessage={<></>}
            >
                <Row>
                    {props?.myTournamentList?.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)?.map((item, index) => {
                        return <TournamentCard item={item} key={index} organizer={true} />
                    })}
                </Row>
            </InfiniteScroll>
            :
            <NoTournament fetchTournamentList={props?.fetchTournamentList} />
        }
    </div>
};

export default MyTournament;