// Images
import { trophy, smallTrophy } from "../../assets/images";

const NoTournament = () => {
    return <div className='mt-5 mb-3 py-5 text-center'>
        <div className="d-none d-md-inline-block">
            <img className='img-fluid mt-5 mb-3' src={trophy} alt='trophy' />
        </div>
        <div className="d-md-none">
            <img className='img-fluid mt-5 mb-3' src={smallTrophy} alt='trophy' />
        </div>
        <p className='fs-25 fw-500 text-secondary d-none d-md-block'>There are no tournaments</p>
        <p className='fs-16 fw-500 text-secondary d-md-none'>There are no tournaments</p>
    </div>
};

export default NoTournament;