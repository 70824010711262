import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { BiArrowBack } from "react-icons/bi";

// Custom Component
import ErrorAlert from '../../components/Alert/ErrorAlert';

// API Service
import { getCustomerTermsAndConditionsService, getOrganizerPrivacyPolicyService } from '../../service/variable.service';

// Redux-Functions
import { selectUser } from "../../redux/userSlice";

const TermsAndCondition = () => {
    const navigate = useNavigate();
    
    const [termsAndConditions, setTermsAndConditions] = useState()
    const user = useSelector(selectUser)

    /**
         * @function fetchCustomerTermsAndConditions
         * @params
         * @description fetch the content of terms and conditions for customer
         */
    const fetchCustomerTermsAndConditions = async () => {
        try {
            const result = await getCustomerTermsAndConditionsService();
            if (result?.data?.status) {
                setTermsAndConditions(result?.data?.data?.value)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function fetchOrganizerTermsAndConditions
         * @params
         * @description fetch the content of terms and conditions for organizer
         */
    const fetchOrganizerTermsAndConditions = async () => {
        try {
            const result = await getOrganizerPrivacyPolicyService();
            if (result?.data?.status) {
                setTermsAndConditions(result?.data?.data?.value)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (user?.type === 'organizer') {
            fetchOrganizerTermsAndConditions()
        } else {
            fetchCustomerTermsAndConditions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
  
    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Terms And Conditions | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <Container className='my-4'>
            <Row>
                <Col xs={12} className='position-relative'>
                    <BiArrowBack role="button" size={20} className='position-absolute top-0 start-0 mt-2 ms-2' onClick={() => navigate(-1)} />
                    <div className='mt-5'>
                        {termsAndConditions && parse(termsAndConditions)}
                    </div>
                </Col>
            </Row>
        </Container>
    </HelmetProvider>
};

export default TermsAndCondition;