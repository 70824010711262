import axios from "axios"

/**
    * @function allCountryService
    * @param
    * @description This function is used to get all country
*/
export const allCountryService = async() =>{
    try {
        const result = await axios.get(`/customer/country/list`)
        return result;
    } catch (error) {
        return error;
    }
}