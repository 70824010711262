import { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import classNames from "classnames";

// Custom Component
import { TournamentCard } from "../Card";
import NoTournament from "./NoTournament";
import ErrorAlert from '../Alert/ErrorAlert';

// API Service
import { allMostVisitedTournamentService, allOrganizerMostVisitedTournamentService } from '../../service/dashboard.service';

// Redux-Functions
import { selectUser } from "../../redux/userSlice"

const MostVisitedTournament = () => {
    const user = useSelector(selectUser);
    
    const [mostVisitedTournamentList, setMostVisitedTournamentList] = useState([])

    /**
         * @function fetchMostVisitedTournamentList
         * @params
         * @description fetch the list of most visited tournament
         */
    const fetchMostVisitedTournamentList = async () => {
        try {
            const result = await allMostVisitedTournamentService();
            if (result?.data?.status) {
                setMostVisitedTournamentList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function fetchOrganizerMostVisitedTournamentList
         * @params
         * @description fetch the list of most visited tournament for organizer
         */
    const fetchOrganizerMostVisitedTournamentList = async () => {
        try {
            const result = await allOrganizerMostVisitedTournamentService();
            if (result?.data?.status) {
                setMostVisitedTournamentList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (user?.type === 'organizer') {
            fetchOrganizerMostVisitedTournamentList();
        } else {
            fetchMostVisitedTournamentList();
        }
        // eslint-disable-next-line
    }, [])

    return <Row className={classNames({
        'my-3': mostVisitedTournamentList.length > 0,
        'my-5': mostVisitedTournamentList.length === 0
    })}>
        {mostVisitedTournamentList.length > 0 ? mostVisitedTournamentList.map((item, index) => {
            return <TournamentCard item={item} key={index} />
        })
            :
            <NoTournament />
        }
    </Row>;
};

export default MostVisitedTournament;