import axios from "axios"

/**
    * @function sendMessageService
    * @param data
    * @description This function is used to send message
*/
export const sendMessageService = async(data) =>{
    try {
        const result = await axios.post(`/customer/contact-us`, data)
        return result;
    } catch (error) {
        return error;
    }
}