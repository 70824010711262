import axios from "axios"

/**
    * @function allBankService
    * @param
    * @description This function is used to get all bank
*/
export const allBankService = async() =>{
    try {
        const result = await axios.get(`/organizer/bank/all`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allBankAccountService
    * @param
    * @description This function is used to get all bank account
*/
export const allBankAccountService = async() =>{
    try {
        const result = await axios.get(`/organizer/bank/list`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function addBankAccountService
    * @param data
    * @description This function is used to add bank account
*/
export const addBankAccountService = async(data) =>{
    try {
        const result = await axios.post(`/organizer/bank/store`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateBankAccountService
    * @param { id, data }
    * @description This function is used to update bank account
*/
export const updateBankAccountService = async({ id, data }) =>{
    try {
        const result = await axios.post(`/organizer/bank/update/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function deleteBankAccountService
    * @param id
    * @description This function is used to delete bank account
*/
export const deleteBankAccountService = async(id) =>{
    try {
        const result = await axios.delete(`/shop/bank/delete/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}