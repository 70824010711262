import DatePicker from "react-datepicker";
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from "react-icons/bs";
import moment from 'moment';

const DatePickerWithYearAndMonth = (props) => {

    /**
         * @function range
         * @params
         * @description for getting the range of arrays in specific range with step
         */
    const range = (start, stop, step) => {
        if (typeof stop == 'undefined') {
            // one param defined
            stop = start;
            start = 0;
        }

        if (typeof step == 'undefined') {
            step = 1;
        }

        if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
            return [];
        }

        var result = [];
        for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
            result.push(i);
        }

        return result;
    };

    const years = range(moment().year() - 100, moment().year() + 1, 1);

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    return <DatePicker
        renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth
        }) => (
            <>
                <div className="d-flex justify-content-between align-items-center fs-16 fw-bold m-3">
                    <BsFillArrowLeftCircleFill role='button' size={20} className={`me-3 text-primary ${months[moment(date).month()] === "January" && moment(date).year() === moment().year() - 100 ? 'pe-none opacity-50' : ''}`} onClick={decreaseMonth} />
                    <div className="d-flex align-items-center">
                        <span className="me-1">{months[moment(date).month()]}</span>
                        <span>{moment(date).year()}</span>
                    </div>
                    <BsFillArrowRightCircleFill role='button' size={20} className={`ms-3 text-primary ${months[moment(date).month()] === "December" && moment(date).year() === moment().year() ? 'pe-none opacity-50' : ''}`} onClick={increaseMonth} />
                </div>
                <div className="d-flex justify-content-center align-items-center m-3">
                    <select
                        value={moment(date).year()}
                        onChange={({ target: { value } }) => changeYear(value)}
                    >
                        {years.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <select
                        value={months[moment(date).month()]}
                        onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                        }
                    >
                        {months.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            </>
        )}
        selected={props.selected}
        onChange={props.onChange}
        customInput={props.customInput}
        dateFormat="dd/MM/yyyy"
        maxDate={props?.maxDate}
        className="form-control"
    />
};

export default DatePickerWithYearAndMonth;