import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import classNames from "classnames";
import { useSelector } from 'react-redux';

// Redux-Functions
import { selectUser } from "../../redux/userSlice";

// Images
import { Logo } from '../../assets/images/svg';

const TabSection = (props) => {
    const user = useSelector(selectUser);

    return <Container fluid>
        <Row className='mt-3'>
            <Col xs={12} lg={6} className='px-0'>
                <Tabs
                    id="fill-tab-example"
                    className="mb-3 bg-gray p-1 rounded border-bottom-0"
                    activeKey={props?.selectedTab}
                    onSelect={(k) => props?.setSelectedTab(k)}
                    fill
                >
                    <Tab eventKey="overview" title={<span className={classNames({ 'text-secondary': props?.selectedTab !== "overview", 'text-primary': props?.selectedTab === "overview" })}>Overview</span>}>
                    </Tab>
                    <Tab eventKey="players" title={<span className={classNames({ 'text-secondary': props?.selectedTab !== "players", 'text-primary': props?.selectedTab === "players" })}>Players</span>}>
                    </Tab>
                    {((user?.type === 'organizer' && props?.tournament?.organization_id === user?.id) || (user?.type === 'organizer' && user?.parent_id && props?.tournament?.subOrganizers?.length > 0 && props?.tournament?.subOrganizers?.some(item => item?.id === user?.id))) &&
                        <Tab eventKey="earnings" title={<span className={classNames({ 'text-secondary': props?.selectedTab !== "earnings", 'text-primary': props?.selectedTab === "earnings" })}>Earnings</span>}>
                        </Tab>
                    }
                </Tabs>
            </Col>
        </Row>
        <div className='d-flex py-2 align-items-center'>
            <div className='me-2'>
                <Logo />
            </div>
            <span className='d-lg-none fs-16 fw-600'>{props?.tournament?.name}</span>
            <span className='d-none d-lg-block fs-22 fw-600'>{props?.tournament?.name}</span>
        </div>
    </Container>
};

export default TabSection;