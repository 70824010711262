const Men = (props) => {
    return <svg width="18" height="19" viewBox="0 0 18 19" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_136_11754)">
            <path d="M1.875 6.3125C1.57664 6.3125 1.29048 6.43103 1.0795 6.642C0.86853 6.85298 0.75 7.13914 0.75 7.4375C0.75 7.73587 0.86853 8.02205 1.0795 8.23303C1.29048 8.444 1.57664 8.5625 1.875 8.5625H2.625C2.625 8.5625 5.625 8.9375 9 5.1875L7.698 6.4895C7.56135 6.6241 7.45316 6.78482 7.37992 6.96209C7.30667 7.13937 7.26985 7.32957 7.27165 7.52137C7.27345 7.71318 7.31383 7.90266 7.39038 8.07853C7.46694 8.2544 7.57807 8.4131 7.71727 8.5451C7.8564 8.6771 8.02072 8.7797 8.20042 8.8469C8.38005 8.9141 8.57138 8.94448 8.763 8.93615C8.95463 8.92783 9.14265 8.88103 9.31582 8.79853C9.489 8.71603 9.6438 8.59955 9.771 8.456L12.15 5.81075C12.2203 5.73264 12.3062 5.67018 12.4022 5.62741C12.4982 5.58464 12.6022 5.56253 12.7073 5.5625H12.75C12.9489 5.5625 13.1397 5.64152 13.2803 5.78217C13.4209 5.92282 13.5 6.11359 13.5 6.3125V7.25C13.5 7.5981 13.6383 7.93194 13.8844 8.17805C14.1306 8.4242 14.4644 8.5625 14.8125 8.5625C15.1606 8.5625 15.4944 8.4242 15.7406 8.17805C15.9867 7.93194 16.125 7.5981 16.125 7.25V6.6875" stroke={props.color || "#475467"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.1875 11.1875C6.08395 11.1875 6 11.1036 6 11C6 10.8964 6.08395 10.8125 6.1875 10.8125" stroke={props.color || "#475467"} strokeWidth="1.5" />
            <path d="M6.1875 11.1875C6.29105 11.1875 6.375 11.1036 6.375 11C6.375 10.8964 6.29105 10.8125 6.1875 10.8125" stroke={props.color || "#475467"} strokeWidth="1.5" />
            <path d="M11.8125 11.1875C11.7089 11.1875 11.625 11.1036 11.625 11C11.625 10.8964 11.7089 10.8125 11.8125 10.8125" stroke={props.color || "#475467"} strokeWidth="1.5" />
            <path d="M11.8125 11.1875C11.9161 11.1875 12 11.1036 12 11C12 10.8964 11.9161 10.8125 11.8125 10.8125" stroke={props.color || "#475467"} strokeWidth="1.5" />
            <path d="M10.5 12.6875C10.5 13.0853 10.342 13.4668 10.0606 13.7481C9.77932 14.0295 9.3978 14.1875 9 14.1875C8.6022 14.1875 8.22068 14.0295 7.93935 13.7481C7.65803 13.4668 7.5 13.0853 7.5 12.6875" stroke={props.color || "#475467"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.875 8.5625V9.6875H1.3125C1.11359 9.6875 0.922822 9.76655 0.782167 9.90718C0.641518 10.0478 0.5625 10.2386 0.5625 10.4375V11.9375C0.5625 12.1364 0.641518 12.3272 0.782167 12.4678C0.922822 12.6084 1.11359 12.6875 1.3125 12.6875H1.875C1.875 13.881 2.34911 15.0255 3.19302 15.8694C4.03693 16.7134 5.18152 17.1875 6.375 17.1875H11.625C12.8185 17.1875 13.963 16.7134 14.8069 15.8694C15.6509 15.0255 16.125 13.881 16.125 12.6875H16.6875C16.8864 12.6875 17.0772 12.6084 17.2178 12.4678C17.3584 12.3272 17.4375 12.1364 17.4375 11.9375V10.4375C17.4375 10.2386 17.3584 10.0478 17.2178 9.90718C17.0772 9.76655 16.8864 9.6875 16.6875 9.6875H16.125V6.3125C16.125 5.11902 15.6509 3.97443 14.8069 3.13052C13.963 2.28661 12.8185 1.8125 11.625 1.8125H6.375C5.18152 1.8125 4.03693 2.28661 3.19302 3.13052C2.34911 3.97443 1.875 5.11902 1.875 6.3125" stroke={props.color || "#475467"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_136_11754">
                <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
            </clipPath>
        </defs>
    </svg>;
}
export default Men;