import { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';

// Custom Component
import { PrimaryOutlineButton } from "../../components/Button";
import AddTShirtModal from '../../components/Modal/AddTShirtModal';

// Redux-Functions
import { selectUser } from "../../redux/userSlice"

const TShirtSetting = () => {
    const user = useSelector(selectUser);

    const [addTShirtModal, setAddTShirtModal] = useState(false)

    /**
        * @function handleAddTShirtModal
        * @params
        * @description set the visibility of add t-shirt modal
    */
    const handleAddTShirtModal = () => {
        setAddTShirtModal(!addTShirtModal)
    }

    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>My Tournament | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <div className='d-flex justify-content-between align-items-center'>
            <p className='fs-25 fw-600 mt-4'>T-Shirts</p>
            {!user?.parent_id &&
                <div>
                    <PrimaryOutlineButton className='fs-14 fw-500 px-5' onClick={handleAddTShirtModal}>Add T-Shirts</PrimaryOutlineButton>
                </div>
            }
        </div>

        {/* Add T-Shirt Modal */}
        <AddTShirtModal show={addTShirtModal} handleClose={handleAddTShirtModal} size='lg' />
    </HelmetProvider>
};

export default TShirtSetting;