import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Spinner } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BiShareAlt } from "react-icons/bi";
import { FiEdit3 } from "react-icons/fi";
import { AiOutlineHeart } from "react-icons/ai";

// Custom Component
import TabSection from '../../components/SingleTournament/TabSection';
import OverviewSection from '../../components/SingleTournament/OverviewSection';
import MyPlayer from '../../components/SingleTournament/MyPlayer';
import MyEarning from '../../components/SingleTournament/MyEarning';
import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';
import UpdateTournamentModal from "../../components/Modal/Tournament/UpdateTournamentModal";
import ShareModal from "../../components/Modal/ShareModal";

// API Service
import { getOrganizerSingleTournamentService, getCustomerSingleTournamentService, getDashboardSingleTournamentService, addCustomerFavoriteService, addOrganizerFavoriteService } from '../../service/tournament.service';

// Redux-Functions
import { selectUser } from "../../redux/userSlice"
import { selectPdfLoading } from "../../redux/tournamentSlice"

const SingleTournament = () => {
    let { tournamentId } = useParams();
    const navigate = useNavigate();

    const [tournament, setTournament] = useState();
    const [selectedTab, setSelectedTab] = useState('overview');
    const [updateTournamentModal, setUpdateTournamentModal] = useState(false)
    const [shareModal, setShareModal] = useState(false)

    const user = useSelector(selectUser);
    const pdfLoading = useSelector(selectPdfLoading);
    LoadingOverlay.propTypes = undefined

    /**
         * @function fetchOrganizerSingleTournament
         * @params
         * @description fetch the single tournament details for organizer
         */
    const fetchOrganizerSingleTournament = async () => {
        try {
            const result = await getOrganizerSingleTournamentService(tournamentId);
            if (result?.data?.status) {
                setTournament(result?.data?.data)
            } else {
                if (result?.response?.data?.message === "Tournament details not found") {
                    fetchDashboardSingleTournament()
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function fetchCustomerSingleTournament
         * @params
         * @description fetch the single tournament details for customer
         */
    const fetchCustomerSingleTournament = async () => {
        try {
            const result = await getCustomerSingleTournamentService(tournamentId);
            if (result?.data?.status) {
                setTournament(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function fetchDashboardSingleTournament
         * @params
         * @description fetch the single tournament details for dashboard
         */
    const fetchDashboardSingleTournament = async () => {
        try {
            const result = await getDashboardSingleTournamentService(tournamentId);
            if (result?.data?.status) {
                setTournament(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function addCustomerFavorite
         * @params
         * @description used to add favorite tournament for customer
         */
    const addCustomerFavorite = async () => {
        try {
            const result = await addCustomerFavoriteService(tournamentId);
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                fetchCustomerSingleTournament()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function addOrganizerFavorite
         * @params
         * @description used to add favorite tournament for organizer
         */
    const addOrganizerFavorite = async () => {
        try {
            const result = await addOrganizerFavoriteService(tournamentId);
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                fetchOrganizerSingleTournament()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function addFavorite
         * @params
         * @description used to add favorite tournament
         */
    const addFavorite = () => {
        if (user?.type === 'customer') {
            addCustomerFavorite()
        } else if(user?.type === 'organizer') {
            addOrganizerFavorite();
        } else {
            navigate('/login')
        }
    }

    useEffect(() => {
        if (user) {
            if (user?.type === 'customer') {
                fetchCustomerSingleTournament()
            } else if (user?.type === 'organizer') {
                fetchOrganizerSingleTournament();
            }
        } else {
            fetchDashboardSingleTournament();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, tournamentId])

    /**
         * @function handleUpdateTournamentModal
         * @params
         * @description set the visibility of update tournament modal
         */
    const handleUpdateTournamentModal = () => {
        setUpdateTournamentModal(!updateTournamentModal)
    }

    /**
         * @function handleShareModal
         * @params
         * @description set the visibility of share modal
         */
    const handleShareModal = () => {
        setShareModal(!shareModal)
    }

    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            {/* Primary Meta Tags */}
            <title>{tournament?.name || "Single Tournament"} | AFA Tournament - Your Gateway to Sporting Excellence!</title>
            <meta name="title" content={tournament?.name || "Single Tournament"} />
            <meta name="description" content={tournament?.description || "Single Tournament Details"} />

            {/* Open Graph / Facebook */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:title" content={tournament?.name || "Single Tournament"} />
            <meta property="og:description" content={tournament?.description || "Single Tournament Details"} />
            <meta property="og:image" content={tournament?.banner_image} />

            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={window.location.href} />
            <meta property="twitter:title" content={tournament?.name || "Single Tournament"} />
            <meta property="twitter:description" content={tournament?.description || "Single Tournament Details"} />
            <meta property="twitter:image" content={tournament?.banner_image} />
        </Helmet>
        <LoadingOverlay
            active={pdfLoading}
            spinner={<Spinner animation="border" />}
        >
            <div className='position-relative'>
                <img className='img-fluid w-100 tournament-banner' src={tournament?.banner_image} alt={tournament?.name} />
                <div className="position-absolute top-0 end-0 d-flex flex-wrap align-items-center px-3 mt-4">
                    <div role='button' className='d-flex flex-wrap align-items-center text-primary bg-event rounded px-3 py-1 me-2' onClick={addFavorite}>
                        <AiOutlineHeart className='me-2' size={20} />
                        {tournament?.favorites?.length > 0 ?
                            <>
                                <span className='fs-14 fw-500 d-none d-lg-block'>Remove from Favorite</span>
                                <span className='fs-14 fw-500 d-lg-none'>Remove</span>
                            </>
                            :
                            <>
                                <span className='fs-14 fw-500 d-none d-lg-block'>Add to Favorite</span>
                                <span className='fs-14 fw-500 d-lg-none'>Favorite</span>
                            </>
                        }
                    </div>
                    {((user?.type === 'organizer' && tournament?.organization_id === user?.id) || (user?.type === 'organizer' && user?.parent_id && tournament?.subOrganizers?.length > 0 && tournament?.subOrganizers?.some(item => item?.id === user?.id))) &&
                        <div role='button' className='d-flex flex-wrap align-items-center text-secondary bg-light rounded border-secondary px-1 px-lg-3 py-1' onClick={handleUpdateTournamentModal}>
                            <FiEdit3 className='me-lg-2' size={20} />
                            <span className='fs-14 fw-500 d-none d-lg-block'>Edit</span>
                        </div>
                    }
                </div>
                <div className="d-flex justify-content-end" style={{ marginTop: '-2.5rem', marginRight: '1rem' }}>
                    <div role='button' className='d-flex text-primary bg-event rounded px-1 px-lg-3 py-1' onClick={handleShareModal}>
                        <BiShareAlt className='me-lg-2' size={20} />
                        <span className='fs-14 fw-500 d-none d-lg-block'>Share</span>
                    </div>
                </div>
            </div>
            <TabSection selectedTab={selectedTab} setSelectedTab={setSelectedTab} tournament={tournament} />
            {selectedTab === 'overview' && <OverviewSection tournament={tournament} fetchOrganizerSingleTournament={fetchOrganizerSingleTournament} handleUpdateTournamentModal={handleUpdateTournamentModal} />}
            {selectedTab === 'players' && <MyPlayer tournament={tournament} fetchOrganizerSingleTournament={fetchOrganizerSingleTournament} />}
            {selectedTab === 'earnings' && <MyEarning tournament={tournament} />}

            {/* Update Tournament Modal */}
            <UpdateTournamentModal show={updateTournamentModal} handleClose={handleUpdateTournamentModal} size='lg' tournament={tournament} fetchOrganizerSingleTournament={fetchOrganizerSingleTournament} />

            {/* Share Modal */}
            <ShareModal show={shareModal} handleClose={handleShareModal} size='lg' />
        </LoadingOverlay>
    </HelmetProvider>
};

export default SingleTournament;