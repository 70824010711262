import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import classNames from "classnames";

// Custom Component
import AllTournament from "./AllTournament";
import ClosingSoonTournament from "./ClosingSoonTournament";
import MostVisitedTournament from "./MostVisitedTournament";

const TabSection = () => {
    const [key, setKey] = useState('all');

    return <Tabs
        id="fill-tab-example"
        className="mb-3 bg-gray p-1 rounded border-bottom-0"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        fill
    >
        <Tab eventKey="all" title={<span className={classNames({'text-secondary': key !== "all", 'rounded': key === "all"})}>All Tournaments</span>}>
            <AllTournament />
        </Tab>
        <Tab eventKey="closing-soon" title={<span className={classNames({'text-secondary': key !== "closing-soon", 'rounded': key === "closing-soon"})}>Closing Soon</span>}>
            <ClosingSoonTournament />
        </Tab>
        <Tab eventKey="most-visited" title={<span className={classNames({'text-secondary': key !== "most-visited", 'rounded': key === "most-visited"})}>Most Popular</span>}>
            <MostVisitedTournament />
        </Tab>
    </Tabs>
};

export default TabSection;