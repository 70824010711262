import { Col, Card } from 'react-bootstrap';
import { Link } from "react-router-dom";
import moment from 'moment';
import { CiLocationOn, CiCalendar } from "react-icons/ci";
import Countdown from 'react-countdown';
import { PiClockClockwiseFill } from "react-icons/pi";

// Images
import { Logo } from "../../assets/images/svg";

export const TournamentCard = (props) => { 
    return <Col xs={12} sm={6} md={4} lg={3} className='p-3'>
        <Link to={`/tournament/${props?.item?.id}`} className='text-dark text-decoration-none'>
            <Card className='rounded-3 h-100'>
                <div className='position-relative'>
                    <Card.Img variant="top" src={props?.item?.banner_image} />
                    <Logo className='position-absolute top-100 end-0 mt-n4 me-2' />
                    {props?.organizer ? moment(new Date(props?.item?.registration_end_date)).diff(moment(new Date()), 'days') >= 0 ?
                        <span className='position-absolute top-100 start-0 bg-success text-light px-3 py-1 mt-n4 ms-3 rounded-pill fs-12 fw-500'>Open</span>
                        :
                        <span className='position-absolute top-100 start-0 bg-danger text-light px-3 py-1 mt-n4 ms-3 rounded-pill fs-12 fw-500'>Closed</span>
                        :
                        <></>
                    }
                </div>
                <Card.Body className='mt-3'>
                    <div className='d-grid'>
                        <Card.Title className='fs-18 fw-600 text-blue text-truncate text-capitalize'>{props?.item?.name}</Card.Title>
                    </div>
                    <div>
                        <CiLocationOn className='text-primary me-2' size={20} />
                        <span className='fs-12 fw-400 text-secondary'>{props?.item?.address}</span>
                    </div>
                    {props?.history ?
                        <div>
                            <CiCalendar className='text-primary me-2' size={20} />
                            <span className='fs-12 fw-500 text-secondary'>{props?.item?.tournament_start_date} to {props?.item?.tournament_end_date}</span>
                        </div>
                        :
                        props?.closingSoon ?
                            <div>
                                <PiClockClockwiseFill className='text-success me-2' size={20} />
                                <Countdown date={moment(props?.item?.registration_end_date)?.endOf('day').toDate()} renderer={({ days, hours, minutes, seconds }) => {
                                    return <span className='fs-12 text-success fw-500 mb-3'>{days > 0 && `${days} day `}{hours < 10 ? `0${hours}:` : `${hours}:`}{`${minutes}:`}{`${seconds}`} hrs</span>;
                                }} />
                            </div>
                            :
                            <div>
                                <CiCalendar className='text-primary me-2' size={20} />
                                <span className='fs-12 fw-500 text-secondary'>{props?.item?.registration_start_date} to {props?.item?.registration_end_date}</span>
                            </div>
                    }
                </Card.Body>
            </Card>
        </Link>
    </Col>
}

export const BankAccountCard = (props) => {
    return <div className='d-flex align-items-center w-100'>
        <img className='img-fluid bank-image me-3' src={props?.item?.bank?.image} alt={props?.item?.bank?.name} />
        <div className='d-flex flex-column align-items-start flex-grow-1'>
            <span className='fs-18 fw-600 mb-2 text-capitalize'>{props?.item?.bank?.name}</span>
            <span className='fs-14 text-secondary'>Holder name : {props?.item?.holder_name}</span>
            <span className='fs-14 text-secondary'>Account no. : {props?.item?.account_number}</span>
        </div>
    </div>
}
