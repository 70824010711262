const Logo = (props) => {
    return <svg width="81" height="81" className={props.className} viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_315_50672)">
            <rect x="8" y="4" width="65" height="65" rx="32.5" fill="white" shapeRendering="crispEdges" />
            <g clipPath="url(#clip0_315_50672)">
                <path d="M55.7147 38.4821C55.7147 38.4821 49.0806 28.876 46.4065 25.3433C44.0939 22.2955 41.3043 22.0191 40.588 22.0038C40.5525 22 40.5243 22 40.5002 22C40.476 22 40.4485 22 40.4123 22.0038C39.696 22.0191 36.9065 22.2955 34.5938 25.3433C31.9197 28.876 25.2856 38.4821 25.2856 38.4821C25.2856 38.4821 23.0568 41.4016 23.0012 43.8776C22.9133 47.808 26.3521 51 30.487 51C34.622 51 36.4249 47.9707 40.3365 47.9407H40.6631C44.5748 47.9707 46.493 51 50.5126 51C54.5322 51 58.0857 47.808 57.9985 43.8776C57.9428 41.4016 55.714 38.4821 55.714 38.4821H55.7147ZM47.2932 37.2358C47.2932 37.2358 44.3313 41.5248 43.1374 43.1016C42.1052 44.4622 40.8597 44.586 40.5397 44.5924C40.5236 44.5943 40.5116 44.5943 40.5008 44.5943C40.4901 44.5943 40.478 44.5943 40.4619 44.5924C40.142 44.5853 38.8965 44.4622 37.8642 43.1016C36.6704 41.5241 33.7085 37.2358 33.7085 37.2358C33.7085 37.2358 32.7138 35.9327 32.6883 34.8268C32.6494 33.0719 34.1847 31.6469 36.0305 31.6469C37.8763 31.6469 38.6812 32.9991 40.4277 33.0125H40.5733C42.3198 32.9991 43.1763 31.6469 44.9705 31.6469C46.7646 31.6469 48.3516 33.0719 48.3127 34.8268C48.2878 35.932 47.2925 37.2358 47.2925 37.2358H47.2932Z" fill="#FA5000" />
            </g>
        </g>
        <defs>
            <filter id="filter0_d_315_50672" x="0" y="0" width="81" height="81" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="4" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_315_50672" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_315_50672" result="shape" />
            </filter>
            <clipPath id="clip0_315_50672">
                <rect width="35" height="29" fill="white" transform="translate(23 22)" />
            </clipPath>
        </defs>
    </svg>
}
export default Logo;
