import { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { LuEye, LuEyeOff } from "react-icons/lu";

// Custom Component
import ErrorText from '../../components/TextField/ErrorText';
import { PrimaryButton } from '../../components/Button';
import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';

// API Service
import { changePasswordService } from '../../service/profile.service';

// Redux-Functions
import { setUser } from "../../redux/userSlice"

// Utility Service
import { removeUserSession } from '../../utils/AuthService';

const ChangePassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

    const { register, setError, reset, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

    /**
         * @function handleShowPassword
         * @params
         * @description toggle the password input field visibilty
         */
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    /**
         * @function handleShowConfirmPassword
         * @params
         * @description toggle the confirm password input field visibilty
         */
    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    /**
         * @function handleShowConfirmNewPassword
         * @params
         * @description toggle the confirm new password input field visibilty
         */
    const handleShowConfirmNewPassword = () => {
        setShowConfirmNewPassword(!showConfirmNewPassword);
    };

    /**
         * @function logout
         * @params
         * @description used to logout the user
         */
    const logout = () => {
        removeUserSession()
        dispatch(setUser())
        navigate('/login')
    }


    /**
         * @function onSubmit
         * @params formdata
         * @description used to change the password of the player
         */
    const onSubmit = async (formdata) => {
        try {
            if (formdata?.new_password !== formdata?.new_password_confirmation) {
                setError('new_password', {
                    type: "manual",
                    message: 'Please make sure your password match.',
                })
                setError('new_password_confirmation', {
                    type: "manual",
                    message: 'Please make sure your password match.',
                })
                return;
            }
            const data = new FormData()
            data.append('current_password', formdata?.current_password)
            data.append('new_password', formdata?.new_password)
            data.append('new_password_confirmation', formdata?.new_password_confirmation)

            setLoading(true)
            const result = await changePasswordService(data);
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                reset()
                logout()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Change Password | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <Container className='mt-5 my-lg-5 pb-lg-5'>
            <Row>
                <Col sm={10} md={8} lg={6} className="mx-auto authDiv p-3 p-lg-5">
                    <span className='fs-30 fw-600'>Change Password</span>
                    <Form className='mt-5 mt-lg-3' onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="mb-4">
                            <Form.Label className='fs-14 fw-500'>Current Password*</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    className='border-end-0'
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Enter password'
                                    {...register('current_password', {
                                        required: "Current Password is required",
                                    })}
                                />
                                <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowPassword}>{showPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                            </InputGroup>
                            {errors.current_password && <ErrorText>{errors.current_password.message}</ErrorText>}
                        </Form.Group>

                        <Form.Group className="mb-4">
                            <Form.Label className='fs-14 fw-500'>New Password*</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    className='border-end-0'
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    placeholder='Enter password'
                                    {...register('new_password', {
                                        required: "New Password is required",
                                    })}
                                />
                                <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowConfirmPassword}>{showConfirmPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                            </InputGroup>
                            {errors.new_password && <ErrorText>{errors.new_password.message}</ErrorText>}
                        </Form.Group>

                        <Form.Group className="mb-5">
                            <Form.Label className='fs-14 fw-500'>Confirm New Password*</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    className='border-end-0'
                                    type={showConfirmNewPassword ? 'text' : 'password'}
                                    placeholder='Enter password'
                                    {...register('new_password_confirmation', {
                                        required: "Confirm New Password is required",
                                    })}
                                />
                                <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowConfirmNewPassword}>{showConfirmNewPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                            </InputGroup>
                            {errors.new_password_confirmation && <ErrorText>{errors.new_password_confirmation.message}</ErrorText>}
                        </Form.Group>

                        <Form.Group>
                            <PrimaryButton disabled={loading} loading={loading} type="submit">Change Password</PrimaryButton>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container>
    </HelmetProvider>
};

export default ChangePassword;