import axios from "axios"

/**
    * @function changePasswordService
    * @param data
    * @description This function is used to change password for player
*/
export const changePasswordService = async (data) => {
    try {
        const result = await axios.post('/customer/auth/change-password', data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getCustomerProfileService
    * @param
    * @description This function is used to get profile details of customer
*/
export const getCustomerProfileService = async () => {
    try {
        const result = await axios.get('/customer/auth/profile')
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getOrganizerProfileService
    * @param
    * @description This function is used to get profile details of organizer
*/
export const getOrganizerProfileService = async () => {
    try {
        const result = await axios.get('/organizer/auth/profile')
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateProfileImageService
    * @param data
    * @description This function is used to update profile image for player
*/
export const updateProfileImageService = async (data) => {
    try {
        const result = await axios.post('/customer/auth/profile-picture', data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateProfileService
    * @param data
    * @description This function is used to update profile data for player
*/
export const updateProfileService = async (data) => {
    try {
        const result = await axios.post('/customer/auth/update-profile', data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updatOrganizerProfileImageService
    * @param data
    * @description This function is used to update profile image for organizer
*/
export const updatOrganizerProfileImageService = async (data) => {
    try {
        const result = await axios.post('/organizer/auth/profile-picture', data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateOrganizerProfileService
    * @param data
    * @description This function is used to update profile data for organizer
*/
export const updateOrganizerProfileService = async (data) => {
    try {
        const result = await axios.post('/organizer/auth/update-profile', data)
        return result;
    } catch (error) {
        return error;
    }
}