import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { BsCheck2 } from "react-icons/bs";
import classNames from "classnames";

// Custom Component
import ErrorText from '../../components/TextField/ErrorText'
import { PrimaryButton } from '../../components/Button'
import AuthFooter from '../../components/Common/AuthFooter'
import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';

// Images
import { logo, authentication } from '../../assets/images'

// API Service
import { resetPasswordService } from '../../service/auth.service';

const ResetPassword = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const { register, watch, clearErrors, setError, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });
    const watchPassword = watch("password")
    const watchPasswordConfirmation = watch("password_confirmation")

    useEffect(() => {
        if (watchPasswordConfirmation && watchPassword !== watchPasswordConfirmation) {
            setError('password', {
                type: "manual",
                message: 'Please make sure your password match.',
            })
            setError('password_confirmation', {
                type: "manual",
                message: 'Please make sure your password match.',
            })
        }
        if (watchPasswordConfirmation && watchPassword === watchPasswordConfirmation) {
            clearErrors('password')
            clearErrors('password_confirmation')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchPassword, watchPasswordConfirmation])

    /**
         * @function handleShowPassword
         * @params
         * @description toggle the password input field visibilty
         */
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    /**
         * @function handleShowConfirmPassword
         * @params
         * @description toggle the confirm password input field visibilty
         */
    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    /**
         * @function onSubmit
         * @params formdata
         * @description used to reset password
         */
    const onSubmit = async (formdata) => { 
        try {
            const data = new FormData()
            if (formdata.password !== formdata.password_confirmation) {
                setError('password', {
                    type: "manual",
                    message: 'Please make sure your password match.',
                })
                setError('password_confirmation', {
                    type: "manual",
                    message: 'Please make sure your password match.',
                })
            }
            data.append('phone', state?.phone)
            data.append('reset_otp_token', state?.reset_otp_token)
            data.append('password', formdata?.password)
            data.append('password_confirmation', formdata?.password_confirmation)
            setLoading(true)
            const result = await resetPasswordService(data);
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                navigate('/login')
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }
    
    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Reset Password | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <Container fluid className='pe-lg-0'>
            <Row>
                <Col xs={12} lg={6} className='d-flex flex-column align-items-start'>
                    <Link to='/'>
                        <img className="img-fluid ms-4 mt-4" src={logo} alt="AFA" />
                    </Link>
                    <Row className="justify-content-center flex-grow-1 w-100 mt-5">
                        <Col xs={10} sm={6} lg={7} className='mb-4'>
                            <Form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
                                <span className='fs-30 fw-bold'>Reset Password</span>
                                <Form.Group className="my-4">
                                    <Form.Label className='fs-14 fw-500'>New Password**</Form.Label>
                                    <InputGroup className='rounded'>
                                        <Form.Control
                                            className='border-end-0'
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Enter password'
                                            {...register('password', {
                                                required: "Password is required"
                                            })}
                                        />
                                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowPassword}>{showPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                                    </InputGroup>
                                    {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
                                    {watchPassword && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/i.test(watchPassword) &&
                                        <div className='d-flex flex-column align-items-start mt-1'>
                                            <div className='d-flex align-items-center'>
                                                <ErrorText className='mb-3'>Your password must contain</ErrorText>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <span className={classNames('fs-14', {'text-success': /^(?=.*[A-Z])/.test(watchPassword) && /^(?=.*[a-z])/.test(watchPassword), 'text-secondary': !/^(?=.*[A-Z])/.test(watchPassword) && !/^(?=.*[a-z])/.test(watchPassword)})}><BsCheck2 className='me-2' size={20} />a lowercase and uppercase letter (a-z,A-Z)</span>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <span className={classNames('fs-14', {'text-success': /^(?=.*[0-9])/.test(watchPassword), 'text-secondary': !/^(?=.*[0-9])/.test(watchPassword)})}><BsCheck2 className='me-2' size={20} />a number</span>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <span className={classNames('fs-14', {'text-success': /^(?=.*\W)/.test(watchPassword), 'text-secondary': !/^(?=.*\W)/.test(watchPassword)})}><BsCheck2 className='me-2' size={20} />a special character($&@!#%^8)</span>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <span className={classNames('fs-14', {'text-success': watchPassword.length >= 8, 'text-secondary': watchPassword.length < 8})}><BsCheck2 className='me-2' size={20} />at least 8 characters</span>
                                            </div>
                                        </div>
                                    }
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label className='fs-14 fw-500'>Confirm New Password*</Form.Label>
                                    <InputGroup className='rounded'>
                                        <Form.Control
                                            className='border-end-0'
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            placeholder='Enter password'
                                            {...register('password_confirmation', {
                                                required: "Confirm Password is required",
                                            })}
                                        />
                                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowConfirmPassword}>{showConfirmPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                                    </InputGroup>
                                    {errors.password_confirmation && <ErrorText>{errors.password_confirmation.message}</ErrorText>}
                                </Form.Group>
                                
                                <Form.Group className="mb-3 mt-4">
                                    <PrimaryButton disabled={loading} loading={loading} type="submit">Reset Password</PrimaryButton>
                                </Form.Group>
                            </Form>
                        </Col>
                        <AuthFooter />
                    </Row>
                </Col>
                <Col xs={12} lg={6} className='d-none d-lg-block'>
                    <img className="img-fluid" src={authentication} alt="Authentication" />
                </Col>
            </Row>
        </Container>
    </HelmetProvider>
};

export default ResetPassword;