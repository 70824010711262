// Images
import { noReceipt } from "../../assets/images";

const NoReceipt = () => {
    return <div className='text-center'>
        <img className='img-fluid mt-5 mb-3' src={noReceipt} alt='No Receipt' />
        <p className='fs-24 fw-600'>No Receipt available at this moment</p>
    </div>
};

export default NoReceipt;