import { Form, InputGroup } from 'react-bootstrap';
import { FiSearch } from "react-icons/fi";

const SearchInput = (props) => {
    return <InputGroup className={`border rounded-3 ${props.className ? props.className: ''}`}>
        <InputGroup.Text className='bg-transparent border-0' id={props.id}>
            <FiSearch />
        </InputGroup.Text>
        <Form.Control
            className='bg-transparent border-0 rounded-3 px-0 search-input'
            placeholder={props.placeholder}
            aria-describedby={props.id}
            onChange={(e) => props.search && props.search(e.target.value)}
        />
    </InputGroup>;
};

export default SearchInput;