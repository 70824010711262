import { Col } from 'react-bootstrap';

const AuthFooter = () => {
    return <Col xs={12} className='d-none d-lg-block mt-auto'>
        <div className='d-flex justify-content-between align-items-center mb-4'>
            <span className='fs-14 fw-400 text-secondary'>
                © AFA 2023
            </span>
            <div className='d-flex align-items-center'>
                <a href="mailto:help@AFA.com" className='text-decoration-none'>
                    <span className='fs-14 fw-400 text-secondary'>
                        help@AFA.com
                    </span>
                </a>
            </div>
        </div>
    </Col>
};

export default AuthFooter;