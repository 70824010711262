import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

// Scroll Component
import ScrollToTop from './components/ScrollToTop';
import DefaultLayout from './components/Layout/DefaultLayout';

const App = () => {
    return <BrowserRouter>
        <ScrollToTop />
        <Routes>
            <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
    </BrowserRouter>
}

export default App;