import axios from "axios"

/**
    * @function allTournamentService
    * @param
    * @description This function is used to get all tournament
*/
export const allTournamentService = async() =>{
    try {
        const result = await axios.get(`/customer/dashboard/get`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allOrganizerTournamentService
    * @param
    * @description This function is used to get all tournament for organizer
*/
export const allOrganizerTournamentService = async() =>{
    try {
        const result = await axios.get(`/organizer/dashboard/get`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allClosingSoonTournamentService
    * @param
    * @description This function is used to get all closing soon tournament
*/
export const allClosingSoonTournamentService = async() =>{
    try {
        const result = await axios.get(`/customer/dashboard/get?closing_soon=1`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allOrganizerClosingSoonTournamentService
    * @param
    * @description This function is used to get all closing soon tournament for organizer
*/
export const allOrganizerClosingSoonTournamentService = async() =>{
    try {
        const result = await axios.get(`/organizer/dashboard/get?closing_soon=1`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allMostVisitedTournamentService
    * @param
    * @description This function is used to get all most visited tournament
*/
export const allMostVisitedTournamentService = async() =>{
    try {
        const result = await axios.get(`/customer/dashboard/get?most_visited=1`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allOrganizerMostVisitedTournamentService
    * @param
    * @description This function is used to get all most visited tournament for organizer
*/
export const allOrganizerMostVisitedTournamentService = async() =>{
    try {
        const result = await axios.get(`/organizer/dashboard/get?most_visited=1`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function searchTournamentService
    * @param keyword
    * @description This function is used to get the list of search tournament
*/
export const searchTournamentService = async(keyword) =>{
    try {
        const result = await axios.get(`/customer/dashboard/get?keyword=${keyword}`)
        return result;
    } catch (error) {
        return error;
    }
}