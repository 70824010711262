import { useState } from "react";
import { Container } from "react-bootstrap";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

// Custom Component
import Sidebar from "../Sidebar";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

// Custom Hook
import useWindowSize from "../Hook/windowSize";

const Layout = (props) => {
    const location = useLocation();
    
    const [isOpen, setIsOpen] = useState(false);

    const windowSize = useWindowSize();

    const tournamentRoute = ['/tournament', '/enter-tournament', '/favorite-tournament', 'tournament-history', '/tournament-summary']
    const authenticatedRoute = ['/login', '/organizer-login', '/register', '/forget-password', '/reset-password']
    const stringRoute = ['/about-us', '/contact-us', '/privacy-policy', '/terms-and-condition']
    
    /**
         * @function toggle
         * @params
         * @description set the visibility of sidebar menu
         */
    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return <div className="App">
        <div className="d-flex flex-grow-1">
            {windowSize?.width >= 992 ?
                <Sidebar toggle={toggle} isOpen={true} authenticatedRoute={authenticatedRoute} stringRoute={stringRoute} />
                :
                <Sidebar toggle={toggle} isOpen={isOpen} authenticatedRoute={authenticatedRoute} stringRoute={stringRoute} />
            }
            <Container fluid className={classNames("content overflow-hidden", { "is-open": isOpen, 'px-0 px-lg-4': (location.pathname?.includes('tournament') && !tournamentRoute.includes(location.pathname)) || location.pathname === '/', 'px-lg-0': authenticatedRoute.includes(location.pathname)})}
            >
                <Navbar toggle={toggle} authenticatedRoute={authenticatedRoute} stringRoute={stringRoute} />
                {props.children}
            </Container>
        </div>
        <div className={classNames("d-block", { "d-none d-lg-block": isOpen })}>
            <Footer authenticatedRoute={authenticatedRoute} stringRoute={stringRoute} />
        </div>
    </div>
}

export default Layout;