import { useState } from 'react';
import { Navbar, Nav, OverlayTrigger, Popover, Row, Col, Offcanvas } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { HiOutlineUser } from "react-icons/hi";
import { AiOutlineMenu } from "react-icons/ai";
import { FiHeart, FiLock } from "react-icons/fi";
import { IoIosSearch, IoMdLogOut } from "react-icons/io";
import classNames from "classnames";

// Custom Component
import { PrimaryButton } from "../Button";
import Menu from "../Menu";
import SearchInput from "../SearchInput/SearchInput";
import ErrorAlert from "../Alert/ErrorAlert";

// Images
import { logo } from "../../assets/images";
import { Avatar } from '../../assets/images/svg'

// Utility Service
import { removeUserSession } from '../../utils/AuthService';

// API Service
import { logoutService, organizerLogoutService } from '../../service/auth.service';

// Redux-Functions
import { setUser, setToken, selectUser } from "../../redux/userSlice"
import { setSearchTerm } from "../../redux/tournamentSlice"

const Header = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const tournamentRoute = ['/tournament', '/enter-tournament', '/favorite-tournament', 'tournament-history', '/tournament-summary']

    const [searchInput, setSearchInput] = useState(false)
    const [menu, setMenu] = useState(false)
    
    /**
        * @function searchTournament
        * @params searchTerm
        * @description used to set the value of search tag
    */
    const searchTournament = (searchTerm) => {
        dispatch(setSearchTerm(searchTerm))
        if (searchTerm) {
            if (location.pathname !== '/search') {
                navigate('/search');
            }
        } else {
            navigate(-1);
        }
    }

    /**
        * @function logout
        * @params
        * @description used to logout the user
    */
    const logout = async () => {
        try {
            let result;
            if (user?.type === 'customer') {
                result = await logoutService();
                if (result?.data?.status) {
                    removeUserSession()
                    dispatch(setUser())
                    dispatch(setToken())
                    navigate('/login')
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } else if (user?.type === 'organizer') {
                result = await organizerLogoutService();
                if (result?.data?.status) {
                    removeUserSession()
                    dispatch(setUser())
                    dispatch(setToken())
                    navigate('/login')
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } else {
                removeUserSession()
                dispatch(setUser())
                dispatch(setToken())
                navigate('/login')
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function handleMenu
        * @params
        * @description used to handle menu
    */
    const handleMenu = () => {
        setMenu(!menu)
    }

    return <>
        <Navbar bg="transparent" className={classNames("navbar border-0 py-3", { "d-none": props.authenticatedRoute.includes(location.pathname) || props.stringRoute.includes(location.pathname), "px-3 px-lg-0": (location.pathname?.includes('tournament') && !tournamentRoute.includes(location.pathname)) || location.pathname === '/' })} expand>
            <AiOutlineMenu role='button' className="d-lg-none" onClick={handleMenu} />
            <Link to="/">
                <img className="img-fluid d-lg-none ms-2" src={logo} alt="AFA" />
            </Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <div className="d-none d-lg-block flex-grow-1 mx-4 mx-lg-0">
                    <SearchInput placeholder='Search tournaments' search={searchTournament} />
                </div>
                <Nav className="ms-auto">
                    <div className="d-flex d-lg-none align-items-center">
                        <IoIosSearch role='button' className="text-secondary" size={26} onClick={() => setSearchInput(!searchInput)} />
                    </div>
                    <Link to="/favorite-tournament" className="d-flex align-items-center mx-2">
                        <FiHeart className="text-secondary" size={24} />
                    </Link>
                    {user ?
                        <OverlayTrigger
                            trigger="click"
                            placement='bottom'
                            rootClose
                            overlay={
                                <Popover className='profile-popover'>
                                    <Popover.Body>
                                        {/* My account */}
                                        <Row className='mb-3' onClick={() => {
                                            navigate('/profile')
                                            document.body.click()
                                        }}>
                                            <Col xs={3} className='text-center pe-0'>
                                                <HiOutlineUser className="text-secondary" size={20} />
                                            </Col>
                                            <Col xs={9} className='px-0'>
                                                <span role='button' className='fs-14 fw-400'>View profile</span>
                                            </Col>
                                        </Row>

                                        {/* Favorite */}
                                        <Row className='mb-3' onClick={() => {
                                            navigate('/favorite-tournament')
                                            document.body.click()
                                        }}>
                                            <Col xs={3} className='text-center pe-0'>
                                                <FiHeart className="text-secondary" size={20} />
                                            </Col>
                                            <Col xs={9} className='px-0'>
                                                <span role='button' className='fs-14 fw-400'>Favorite</span>
                                            </Col>
                                        </Row>
                                    
                                        {/* Change Password */}
                                        {user?.type === 'customer' &&
                                            <Row className='mb-3' onClick={() => {
                                                navigate('/change-password')
                                                document.body.click()
                                            }}>
                                                <Col xs={3} className='text-center pe-0'>
                                                    <FiLock className="text-secondary" size={20} />
                                                </Col>
                                                <Col xs={9} className='px-0'>
                                                    <span role='button' className='fs-14 fw-400'>Change Password</span>
                                                </Col>
                                            </Row>
                                        }

                                        <hr />

                                        {/* Log Out */}
                                        <Row className='mb-2'>
                                            <Col xs={3} className='text-center pe-0'>
                                                <IoMdLogOut className="text-danger" size={20} />
                                            </Col>
                                            <Col xs={9} className='px-0'>
                                                <span role='button' className='fs-14 fw-400 text-danger' onClick={logout}>Logout</span>
                                            </Col>
                                        </Row>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <div role='button' className="d-flex justify-content-center align-items-center">
                                {user?.type === 'customer' && user?.profile_pic ?
                                    <img className='profile-small-photo border rounded-circle me-lg-2' src={user?.profile_pic} alt={user?.username || user?.name} />
                                    :
                                    user?.type === 'organizer' && user?.profile_image ?
                                        <img className='profile-small-photo border rounded-circle me-lg-2' src={user?.profile_image} alt={user?.username || user?.name} />
                                        :
                                        <Avatar className="me-lg-2" size={30} />
                                }
                                <span className="fw-600 text-secondary d-none d-lg-block">{user?.type === 'customer' ? user?.username : user?.name}</span>
                            </div>
                        </OverlayTrigger>
                        :
                        <Link to="/login">
                            <PrimaryButton>Sign In</PrimaryButton>
                        </Link>
                    }
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        <Row className={classNames('w-100 my-3 mx-0', { 'd-block d-lg-none': searchInput, 'd-none': !searchInput })}>
            <SearchInput placeholder='Search tournaments' search={searchTournament} />
        </Row>
        <Offcanvas className='min-vw-100 d-lg-none sidebar px-3' show={menu} onHide={handleMenu} backdrop="static">
            <Menu toggle={handleMenu} />
        </Offcanvas>
    </>
}

export default Header;