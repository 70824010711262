import { Modal, Row, Col } from 'react-bootstrap';
import { IoCloseOutline } from "react-icons/io5";

// Custom Component
import { PrimaryButton } from '../Button';

// Images
import { Error } from '../../assets/images/svg';

const PaymentErrorModal = (props) => {
    /**
         * @function calculateSum
         * @params array, property
         * @description used to calculate sum of specific property ib array
         */
    const calculateSum = (array, property) => {
        if (array) {
            const total = array.reduce((accumulator, object) => {
                return accumulator + object[property];
            }, 0);
            return total;
        } else {
            return 0
        }
    }

    return <Modal show={props.show} onHide={props.handleClose} backdrop="static" size='lg' centered>
        <Modal.Header className='d-flex flex-column justify-content-center align-items-center border-0 bg-danger text-light px-0 py-5 position-relative'>
            <IoCloseOutline role='button' className='text-light position-absolute top-0 end-0 mt-2 me-2' size={20} onClick={props.handleClose} />
            <Error />
            <span className='fs-30 fw-600 mt-3 mb-2'>Oops!</span>
            <span className='text-center'>Something went Wrong, Please try again later</span>
        </Modal.Header>
        <Modal.Body className='d-flex flex-column align-items-center'>
            {/* {props?.tournament?.category_type === "INDIVIDUAL" &&
                <div className='d-flex justify-content-between align-items-center w-100 mt-2 fw-600'>
                    <span>Original Price</span>
                    <span>RM{parseFloat(calculateSum(props?.tournament?.player_details, 'event_price')).toFixed(2)}</span>
                </div>
            }
            {props?.tournament?.category_type === "TEAM" &&
                <div className='d-flex justify-content-between align-items-center w-100 mt-2 fw-600'>
                    <span>Team Event Price</span>
                    <span>RM{parseFloat(props?.tournament?.team_event_price).toFixed(2)}</span>
                </div>
            }
            <div className='d-flex justify-content-between align-items-center w-100 text-secondary mt-2'>
                <span>Players</span>
                <span>{props?.tournament?.player_details?.length || 0}</span>
            </div>
            {props?.tournament?.category_type === "INDIVIDUAL" && props?.tournament?.player_details &&
                <div className='d-flex justify-content-between align-items-center w-100 mt-2 fw-600'>
                    <span>Total Price</span>
                    <span>RM{parseFloat(calculateSum(props?.tournament?.player_details, 'event_price')).toFixed(2)}</span>
                </div>
            }
            {props?.tournament?.category_type === "TEAM" && props?.tournament?.player_details &&
                <div className='d-flex justify-content-between align-items-center w-100 mt-2 fw-600'>
                    <span>Total Price</span>
                    <span>RM{parseFloat(props?.tournament?.team_event_price).toFixed(2)}</span>
                </div>
            }
            <Row className='w-100 my-3 justify-content-center'>
                <Col xs={6} md={4}>
                    <PrimaryButton className='fs-14 fw-500' onClick={props.handleClose}>Try again</PrimaryButton>
                </Col>
            </Row> */}
            <span role='button' className='fs-16 fw-bold text-secondary mb-4' onClick={props.handleClose}>Go back</span>
        </Modal.Body>
    </Modal>
};

export default PaymentErrorModal;