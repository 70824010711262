import { Modal, InputGroup, Form, Button } from 'react-bootstrap';
import { EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon } from "react-share";

// Custom Component
import SuccessAlert from '../Alert/SuccessAlert';

const ShareModal = (props) => {  
    /**
         * @function copyLink
         * @params
         * @description used to copy link
         */
    const copyLink = async () => {
        navigator.clipboard.writeText(window.location.href)
        SuccessAlert('Link copied to clipboard')
    }

    return <Modal show={props.show} onHide={props.handleClose} centered>
        <Modal.Header className='border-0 fs-18 fw-600' closeButton>
            Share
        </Modal.Header>
        <Modal.Body className='d-flex flex-column align-items-center'>
            <div className='d-flex align-items-center mb-4'>
                <WhatsappShareButton url={window.location.href}>
                    <WhatsappIcon className='me-2' size={48} round />
                </WhatsappShareButton>
                <FacebookShareButton url={window.location.href}>
                    <FacebookIcon className='me-2' size={48} round />
                </FacebookShareButton>
                <TwitterShareButton url={window.location.href}>
                    <TwitterIcon className='me-2' size={48} round />
                </TwitterShareButton>
                <EmailShareButton url={window.location.href}>
                    <EmailIcon size={48} round />
                </EmailShareButton>
            </div>
            <InputGroup className="mb-3 rounded">
                <Form.Control
                    value={window.location.href}
                    disabled
                />
                <Button variant="primary" className='text-light fs-14' onClick={copyLink}>
                    Copy
                </Button>
            </InputGroup>
        </Modal.Body>
    </Modal>
};

export default ShareModal;