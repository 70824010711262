import { useState, useEffect } from 'react';
import { Row, Spinner } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

// Custom Component
import { TournamentCard } from "../Card";
import NoTournamentHistory from "./NoTournamentHistory";
import ErrorAlert from '../Alert/ErrorAlert';

// API Service
import { allEnteredTournamentHistoryService } from '../../service/tournament.service';

const MyTournamentHistory = () => {
    const [enteredTournamentHistoryList, setEnteredTournamentHistoryList] = useState([])
    const [pageLoad, setPageLoad] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);

    const perPage = 12;

    /**
         * @function fetchEnteredTournamentHistoryList
         * @params
         * @description fetch the list of entered tournament history
         */
    const fetchEnteredTournamentHistoryList = async (page) => {
        try {
            const result = await allEnteredTournamentHistoryService({
                paginate: 1,
                page,
                perPage
            });
            if (result?.data?.status) {
                setEnteredTournamentHistoryList(result?.data?.data?.tournament_lists)
                if (result?.data?.data?.pagination?.current_page === result?.data?.data?.pagination?.total_pages) {
                    setPageLoad(false)
                } else {
                    setPageLoad(true)
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function fetchMoreEnteredTournament
         * @params
         * @description fetch more entered tournament
         */
    const fetchMoreEnteredTournament = () => { 
        const nextPage = pageNumber + 1;
        setPageNumber(nextPage);
        fetchEnteredTournamentHistoryList(nextPage)
    }

    useEffect(() => {
        fetchEnteredTournamentHistoryList(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Row className='my-3'>
        {enteredTournamentHistoryList?.length > 0 ?
            <InfiniteScroll
                className='overflow-hidden'
                dataLength={enteredTournamentHistoryList?.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)?.length}
                next={fetchMoreEnteredTournament}
                hasMore={pageLoad}
                loader={<h4 className='text-center'>
                    <Spinner animation="border" />
                </h4>}
                endMessage={<></>}
            >
                <Row>
                    {enteredTournamentHistoryList?.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)?.map((item, index) => {
                        return <TournamentCard item={item} key={index} history={true} />
                    })}
                </Row>
            </InfiniteScroll>
            :
            <NoTournamentHistory />
        }
    </Row>;
};

export default MyTournamentHistory;