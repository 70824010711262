import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { BiArrowBack } from "react-icons/bi";

// Custom Component
import ErrorAlert from '../../components/Alert/ErrorAlert';

// API Service
import { getCustomerAboutUsService, getOrganizerAboutUsService } from '../../service/variable.service';

// Redux-Functions
import { selectUser } from "../../redux/userSlice";

const AboutUs = () => {
    const navigate = useNavigate();
    
    const [aboutUs, setAboutUs] = useState()
    const user = useSelector(selectUser)

    /**
         * @function fetchCustomerAboutUs
         * @params
         * @description fetch the content of about us for customer
         */
    const fetchCustomerAboutUs = async () => {
        try {
            const result = await getCustomerAboutUsService();
            if (result?.data?.status) {
                setAboutUs(result?.data?.data?.value)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function fetchOrganizerAboutUs
         * @params
         * @description fetch the content of about us for organizer
         */
    const fetchOrganizerAboutUs = async () => {
        try {
            const result = await getOrganizerAboutUsService();
            if (result?.data?.status) {
                setAboutUs(result?.data?.data?.value)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (user?.type === 'organizer') {
            fetchOrganizerAboutUs()
        } else {
            fetchCustomerAboutUs();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
  
    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>About Us | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <Container className='my-4'>
            <Row>
                <Col xs={12} className='position-relative'>
                    <BiArrowBack role="button" size={20} className='position-absolute top-0 start-0 mt-2 ms-2' onClick={() => navigate(-1)} />
                    <div className='mt-5'>
                        {aboutUs && parse(aboutUs)}
                    </div>
                </Col>
            </Row>
        </Container>
    </HelmetProvider>
};

export default AboutUs;