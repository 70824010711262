import { Link } from "react-router-dom";

// Custom Component
import { PrimaryOutlineButton } from "../Button";

// Images
import { trophy, smallTrophy } from "../../assets/images";

const NoTournamentHistory = () => {
    return <div className='my-5 text-center'>
        <div className="d-none d-md-inline-block">
            <img className='img-fluid mt-5 mb-3' src={trophy} alt='trophy' />
        </div>
        <div className="d-md-none">
            <img className='img-fluid mt-5 mb-3' src={smallTrophy} alt='trophy' />
        </div>
        <p className='fs-25 fw-500 text-secondary d-none d-md-block'>You Haven’t entered any Tournaments yet!</p>
        <p className='fs-16 fw-500 text-secondary d-md-none'>You Haven’t entered any Tournaments yet!</p>
        <div className='d-inline-block mt-3'>
            <Link to='/'>
                <PrimaryOutlineButton className='fs-14 fw-500 px-5'>Home</PrimaryOutlineButton>
            </Link>
        </div>
    </div>
};

export default NoTournamentHistory;