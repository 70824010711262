import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

// Custom Component
import ErrorAlert from '../../components/Alert/ErrorAlert';
import PaymentErrorModal from "../../components/Modal/PaymentErrorModal";

// API Service
import { teamPlayerService } from '../../service/tournament.service';

const PaymentFailure = () => {
    let { tournamentId, teamId } = useParams();

    const [tournament, setTournament] = useState();
    const [paymentErrorModal, setPaymentErrorModal] = useState(false)

    /**
         * @function fetchCustomerSingleTournament
         * @params
         * @description fetch the single tournament details for customer
         */
    const fetchCustomerSingleTournament = async () => {
        try {
            const result = await teamPlayerService({
                id: tournamentId,
                teamId
            });
            if (result?.data?.status) {
                setTournament(result?.data?.data)
                handlePaymentErrorModal()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCustomerSingleTournament()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tournamentId])

    /**
         * @function handlePaymentErrorModal
         * @params
         * @description set the visibility of payment error modal
         */
    const handlePaymentErrorModal = () => {
        setPaymentErrorModal(!paymentErrorModal)
    }
    
    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Payment Failure | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        {/* Payment Error Modal */}
        <PaymentErrorModal show={paymentErrorModal} handleClose={handlePaymentErrorModal} tournament={tournament} size='lg' />
    </HelmetProvider>
};

export default PaymentFailure;