import { useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Custom Component
import CurrentBalance from "../../components/Common/CurrentBalance";
import MyTransaction from "../../components/Wallet/MyTransaction";
import ErrorAlert from '../../components/Alert/ErrorAlert';

// API Service
import { allTransactionHistoryService } from '../../service/wallet.service';

const Wallet = () => {
    const [transactionHistoryList, setTransactionHistoryList] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [totalEntry, setTotalEntry] = useState(1)

    /**
         * @function fetchTransactionHistoryList
         * @params
         * @description fetch the list of transaction history
         */
    const fetchTransactionHistoryList = async () => {
        try {
            const result = await allTransactionHistoryService({
                paginate: 1,
                page: currentPage + 1,
                perPage: 10,
                keyword: ''
            });
            if (result?.data?.status) {
                setTransactionHistoryList(result?.data?.data?.wallet_transactions?.data)
                setTotalEntry(result?.data?.data?.pagination?.total_pages || 1)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Wallet | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <CurrentBalance modal={false} fetchTransactionHistoryList={fetchTransactionHistoryList} />
        <MyTransaction fetchTransactionHistoryList={fetchTransactionHistoryList} transactionHistoryList={transactionHistoryList} currentPage={currentPage} setCurrentPage={setCurrentPage} totalEntry={totalEntry} />
    </HelmetProvider>
};

export default Wallet;