import { Route, Routes } from 'react-router-dom';

// Custom Component
import Layout from './Layout';

// Authentication Page
import SignIn from '../../pages/SignIn/SignIn';
import OrganizerSignIn from '../../pages/OrganizerSignIn/OrganizerSignIn';
import SignUp from '../../pages/SignUp/SignUp';
import ForgotPassword from '../../pages/ForgotPassword/ForgotPassword';
import ResetPassword from '../../pages/ResetPassword/ResetPassword';

// Public Page
import Homepage from '../../pages/Homepage/Homepage';
import Tournament from '../../pages/Tournament/Tournament';
import TShirtSetting from '../../pages/TShirtSetting/TShirtSetting';
import SingleTournament from '../../pages/SingleTournament/SingleTournament';
import AboutUs from '../../pages/AboutUs/AboutUs';
import ContactUs from '../../pages/ContactUs/ContactUs';
import PrivacyPolicy from '../../pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndCondition from '../../pages/TermsAndCondition/TermsAndCondition';

// Private Page
import BankAccount from '../../pages/BankAccount/BankAccount';
import ChangePassword from '../../pages/ChangePassword/ChangePassword';
import EnterTournament from '../../pages/EnterTournament/EnterTournament';
import FavoriteTournament from '../../pages/FavoriteTournament/FavoriteTournament';
import PaymentFailure from '../../pages/PaymentFailure/PaymentFailure';
import PaymentSuccess from '../../pages/PaymentSuccess/PaymentSuccess';
import Profile from '../../pages/Profile/Profile';
import TournamentHistory from '../../pages/TournamentHistory/TournamentHistory';
import TournamentSummary from '../../pages/TournamentSummary/TournamentSummary';
import SearchTournament from '../../pages/SearchTournament/SearchTournament';
import Wallet from '../../pages/Wallet/Wallet';
import SubOrganizer from '../../pages/SubOrganizer/SubOrganizer';
import Receipt from '../../pages/Receipt/Receipt';
import SSO from '../../pages/SSO/SSO';

// Auth-Service
import PrivateRoute from '../../utils/PrivateRoute';

const DefaultLayout = () => {
    return <Layout>
        <Routes>
            {/* Authentication Route */}
            <Route path="/login" element={<SignIn />} />
            <Route path="/organizer-login" element={<OrganizerSignIn />} />
            <Route path="/register" element={<SignUp />} />
            <Route path="/forget-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            {/* Home Page Route */}
            <Route path="/" element={<Homepage />} />
            {/* Single Tournament Page Route */}
            <Route path="/tournament/:tournamentId" element={<SingleTournament />} />
            {/* About Us Page Route */}
            <Route path="/about-us" element={<AboutUs />} />
            {/* Contact Us Page Route */}
            <Route path="/contact-us" element={<ContactUs />} />
            {/* Privacy Policy Page Route */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            {/* Terms And Condition Page Route */}
            <Route path="/terms-and-condition" element={<TermsAndCondition />} />
            {/* Search Tournament Page Route */}
            <Route path="/search" element={<SearchTournament />} />

            {/* SSO Page Route */}
            <Route path="/sso" element={<SSO />} />

            {/* Private Route */}
            <Route element={<PrivateRoute />}>
                {/* Tournament Page Route */}
                <Route path="/tournament" element={<Tournament />} />
                {/* Tshirt Setting Page Route */}
                <Route path="/tshirt-setting" element={<TShirtSetting />} />
                {/* Bank Account Page Route */}
                <Route path="/bank-account" element={<BankAccount />} />
                {/* Password Change Page Route */}
                <Route path="/change-password" element={<ChangePassword />} />
                {/* Enter Tournament Page Route */}
                <Route path="/enter-tournament" element={<EnterTournament />} />
                {/* Favorite Tournament Page Route */}
                <Route path="/favorite-tournament" element={<FavoriteTournament />} />
                {/* Payment Failure Page Route */}
                <Route path="/payment/failure/:tournamentId/:teamId" element={<PaymentFailure />} />
                {/* Payment Success Page Route */}
                <Route path="/payment/success/:tournamentId/:teamId" element={<PaymentSuccess />} />
                {/* Profile Page Route */}
                <Route path="/profile" element={<Profile />} />
                {/* Tournament History Page Route */}
                <Route path="/tournament-history" element={<TournamentHistory />} />
                {/* Tournament Summary Page Route */}
                <Route path="/tournament-summary" element={<TournamentSummary />} />
                {/* Wallet Page Route */}
                <Route path="/wallet" element={<Wallet />} />
                {/* Sub Organizer Page Route */}
                <Route path="/sub-organizer" element={<SubOrganizer />} />
                {/* Receipt Page Route */}
                <Route path="/receipts" element={<Receipt />} />
            </Route>
        </Routes>
    </Layout>
}

export default DefaultLayout