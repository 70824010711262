import { useState, useEffect } from 'react';
import { Modal, Form, Spinner } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

// Custom Component
import CurrentBalance from '../Common/CurrentBalance';
import { BankAccountCard } from '../Card';
import NoBank from '../Common/NoBank';
import SuccessAlert from '../Alert/SuccessAlert'
import ErrorAlert from '../Alert/ErrorAlert'
import { PrimaryButton } from '../Button';

// API Service
import { withdrawWalletService } from '../../service/wallet.service';
import { allBankAccountService } from '../../service/bank.service';

// Redux-Functions
import { setBankAccountList, selectBankAccountList } from "../../redux/bankAccountSlice"

const WithdrawWalletModal = (props) => {
    const dispatch = useDispatch();
    const bankAccountList = useSelector(selectBankAccountList)
    
    const [loading, setLoading] = useState(false);
    LoadingOverlay.propTypes = undefined
    
    const { handleSubmit } = useForm({ mode: "onBlur" });

    /**
         * @function fetchBankAccountList
         * @params
         * @description fetch the list of bank account
         */
    const fetchBankAccountList = async () => {
        try {
            setLoading(true)
            const result = await allBankAccountService();
            if (result?.data?.status) {
                dispatch(setBankAccountList(result?.data?.data))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchBankAccountList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
         * @function onSubmit
         * @params
         * @description used to wihdraw wallet for organizer
         */
    const onSubmit = async () => {
        const data = new FormData()
        data.append('amount', Number.isInteger(props?.currentBalance) ? props?.currentBalance : props?.currentBalance?.toFixed(2))
        try {
            setLoading(true)
            const result = await withdrawWalletService(data);
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                props?.fetchTransactionHistoryList()
                props?.fetchCurrentBalance()
                props.handleClose()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }
    
    return <Modal show={props.show} onHide={props.handleClose} centered>
        <LoadingOverlay
            active={loading}
            spinner={<Spinner animation="border" />}
        >
            <Modal.Header className='fs-24 fw-600 border-0' closeButton>
                Withdraw
            </Modal.Header>
            <Modal.Body className='d-flex flex-column pt-0 align-items-center'>
                <div className='w-100'>
                    <CurrentBalance modal={true} />

                    <Form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
                        {bankAccountList?.length > 0 ?
                            <div className='d-flex flex-column align-items-center border bg-gray rounded p-3'>
                                <div className='d-flex justify-content-between align-items-center w-100'>
                                    <span className='fw-600'>Bank Detail</span>
                                </div>
                                {bankAccountList?.map((item, index) => {
                                    return <BankAccountCard item={item} key={index} />
                                })}
                            </div>
                            :
                            <NoBank handleAddBankAccountModal={props.handleAddBankAccountModal} />
                        }

                        <div className='d-flex justify-content-end align-items-end mt-4'>
                            <div>
                                <PrimaryButton className='fs-14 fw-500 px-5' disabled={bankAccountList?.length === 0 || loading} loading={loading} type="submit">Withdraw</PrimaryButton>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </LoadingOverlay>
    </Modal>
};

export default WithdrawWalletModal;