import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';

// Custom Component
import { PrimaryButton } from '../../components/Button'
import AuthFooter from '../../components/Common/AuthFooter'
import ErrorAlert from '../../components/Alert/ErrorAlert';

// Images
import { logo, smallLogo, authentication } from '../../assets/images'

// Utility Service
import { setUserSession, updateUserSession, getToken } from '../../utils/AuthService';

// API Service
import { loginService, loginAFAService } from '../../service/auth.service';
import { getCustomerProfileService } from '../../service/profile.service';

// Redux-Functions
import { setToken, setUser } from "../../redux/userSlice"

const SignIn = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(() => {
        // navigate to home page if login
        if (getToken()) {
            navigate('/')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
        * @function fetchUserInfo
        * @params
        * @description fetch the info of user
    */
    const fetchUserInfo = async () => {
        try {
            const result = await getCustomerProfileService();
            if (result?.data?.status) {
                dispatch(setUser({
                    ...result.data?.data,
                    type: 'customer'
                }))
                updateUserSession({
                    ...result.data?.data,
                    type: 'customer'
                })
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

     /**
        * @function onSubmit
        * @params 
        * @description used to signin the player
    */
    const onSubmit = async () => {
        try {
            const result = await loginService({
                phone: '123147951',
                password: 'Test@123'
            });
            if (result?.data?.status) {
                dispatch(setToken(result?.data?.data?.token))
                dispatch(setUser({
                    ...result.data?.data?.customer,
                    type: 'customer'
                }))
                setUserSession(result.data?.data?.token, {
                    ...result.data?.data?.customer,
                    type: 'customer'
                })
                await fetchUserInfo()
                navigate('/');
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function handleAFASignIn
        * @params
        * @description handle the sign in via AFA
    */
    const handleAFASignIn = async () => {
        try {
            const result = await loginAFAService();
            if (result?.data?.status) {
                window.location.href = result?.data?.data?.url
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Sign In | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <Container fluid className='pe-lg-0'>
            <Row>
                <Col xs={12} lg={6} className='d-flex flex-column align-items-start'>
                    <Link to='/'>
                        <img className="img-fluid ms-4 mt-4" src={logo} alt="AFA" />
                    </Link>
                    <Row className="justify-content-center align-items-center flex-grow-1 w-100 mt-5">
                        <Col xs={12} sm={7} className='border border-2 p-4'>
                            <span className='fs-30 fw-bold'>Sign In</span>
                            {/* <Form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
                                
                                {countryList.length > 0 &&
                                    <Form.Group className="my-4">
                                        <Form.Label className='fs-14 fw-500'>Phone number*</Form.Label>
                                        <InputGroup className='rounded'>
                                            <InputGroup.Text className='p-0 border-0'>
                                                <Form.Select
                                                    className='border-end-0'
                                                    {...register("country_id")}
                                                >
                                                    {countryList.map((item, index) => {
                                                        return <option value={item?.id} key={index}>{item?.code}</option>
                                                    })}
                                                </Form.Select>
                                            </InputGroup.Text>
                                            <Form.Control
                                                className='border-start-0 ps-0'
                                                type="tel"
                                                placeholder="Enter Phone number"
                                                {...register("phone", {
                                                    required: "Phone is required",
                                                    pattern: {
                                                        value: /^[0-9]+$/,
                                                        message: 'Please enter only number'
                                                    }
                                                })}
                                            />
                                        </InputGroup>
                                        {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                                    </Form.Group>
                                }
                                    
                                <Form.Group className="mb-4">
                                    <Form.Label className='fs-14 fw-500'>Password*</Form.Label>
                                    <InputGroup className='rounded'>
                                        <Form.Control
                                            className='border-end-0'
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Enter password'
                                            {...register('password', {
                                                required: "Password is required"
                                            })}
                                        />
                                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowPassword}>{showPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                                    </InputGroup>
                                    {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
                                </Form.Group>

                                <div className='d-flex justify-content-end'>
                                    <Link to='/forget-password' className='text-decoration-none'>
                                        <span className='fs-14 fw-bold text-primary'>Forgot Password?</span>
                                    </Link>
                                </div>
                                
                                <Form.Group className="mb-3 mt-4">
                                    <PrimaryButton type="submit">Sign In</PrimaryButton>
                                </Form.Group>
                            </Form> */}
                            {/* <hr className='mx-5 my-3' /> */}
                            <PrimaryButton className='mt-4 mb-2' onClick={handleAFASignIn}>
                            {/* <PrimaryButton className='mt-4 mb-2' onClick={onSubmit}> */}
                                <img className="img-fluid me-2" src={smallLogo} alt="AFA" />
                                Sign In via AFA
                            </PrimaryButton>
                            <hr className='mx-5 my-3' />
                            {/* <div className='d-flex justify-content-end'>
                                <Link to='/forget-password' className='text-decoration-none'>
                                    <span className='fs-14 fw-bold text-primary'>Forgot Password?</span>
                                </Link>
                            </div> */}
                            <Link to='/organizer-login' className='text-decoration-none'>
                                <div role='button' className='my-3 bg-danger bg-opacity-10 text-center rounded py-2'>
                                    <span className='text-primary fw-600 px-3'>Sign in as Organizer</span>
                                </div>
                            </Link>
                            {/* <div className='text-center mt-4 mb-5 mb-lg-0'>
                                <span className='fs-14 fw-500 text-center'>
                                    Don&lsquo;t have an account?
                                    <Link to='/register' className='text-decoration-none'>
                                        <span className='ms-1 fw-bold text-primary'>Sign Up</span>
                                    </Link>
                                </span>
                            </div> */}
                        </Col>
                        <AuthFooter />
                    </Row>
                </Col>
                <Col xs={12} lg={6} className='d-none d-lg-block'>
                    <img className="img-fluid" src={authentication} alt="Authentication" />
                </Col>
            </Row>
        </Container>
    </HelmetProvider>
};

export default SignIn;