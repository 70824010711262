import { forwardRef } from "react";
import { CiCalendar } from "react-icons/ci";

const CustomDatePicker = forwardRef((props, ref) => {
    return <div className="input-group mb-3" onClick={props.onClick} ref={ref}>
        <input type="text" className="form-control border-end-0" value={props.value} aria-describedby={props.id} />
        <span role="button" className="input-group-text bg-transparent border-start-0" id={props.id}>
            <CiCalendar color={"#707070"} />
        </span>
    </div>;
});

export default CustomDatePicker;