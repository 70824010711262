// Images
import { noSuborganizer } from "../../assets/images";

const NoSubOrganizer = () => {
    return <div className='text-center'>
        <img className='img-fluid mt-5 mb-3' src={noSuborganizer} alt='No Suborganizer' />
        <p className='fs-24 fw-600'>You Don’t Added sub-Organizer yet!</p>
    </div>
};

export default NoSubOrganizer;