import { configureStore } from "@reduxjs/toolkit";

import bankAccountReducer from "./bankAccountSlice";
import tournamentReducer from "./tournamentSlice";
import userReducer from "./userSlice";

export default configureStore({
    reducer: {
        bankAccount: bankAccountReducer,
        tournament: tournamentReducer,
        user: userReducer
    }
});