import { useState, useEffect } from 'react';
import { Modal, Form, Spinner, Row, Col, InputGroup } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import { LuEye, LuEyeOff } from "react-icons/lu";
import { useForm } from 'react-hook-form';

// Custom Component
import ErrorText from '../TextField/ErrorText'
import SuccessAlert from '../Alert/SuccessAlert'
import ErrorAlert from '../Alert/ErrorAlert'
import { PrimaryButton } from '../Button';

// Images
import { Image } from '../../assets/images/svg';

// API Service
import { updateSubOrganizerService } from '../../service/subOrganizer.service';
import { allCountryService } from '../../service/country.service';

const EditSubOrganizerModal = (props) => {
    const [subOrganizerImage, setSubOrganizerImage] = useState(null);
    const [countryList, setCountryList] = useState([])
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    LoadingOverlay.propTypes = undefined
    
    const { register, setValue, reset, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

    /**
         * @function fetchCountryList
         * @params
         * @description fetch the list of country
         */
    const fetchCountryList = async () => {
        try {
            const result = await allCountryService();
            if (result?.data?.status) {
                setCountryList(result?.data?.data)
                if (result?.data?.data?.length > 0) {
                    setValue("country_id", result?.data?.data[0]?.id)
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCountryList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // for setting default values
    useEffect(() => {
        if (props?.selectedItem) {
            let defaultValues = {};
            defaultValues.name = props?.selectedItem?.name
            defaultValues.email = props?.selectedItem?.email
            defaultValues.phone = props?.selectedItem?.phone
            defaultValues.country_id = props?.selectedItem?.country_id
            defaultValues.address = props?.selectedItem?.address
            defaultValues.gender = props?.selectedItem?.gender
            defaultValues.password = props?.selectedItem?.password
            defaultValues.password_confirmation = props?.selectedItem?.password_confirmation
            if (props?.selectedItem?.profile_image) {
                setSubOrganizerImage(props?.selectedItem?.profile_image)
            }
            reset({ ...defaultValues })
        } else {
            reset()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    /**
         * @function handleShowPassword
         * @params
         * @description toggle the password input field visibilty
         */
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    /**
         * @function handleShowConfirmPassword
         * @params
         * @description toggle the confirm password input field visibilty
         */
    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    /**
         * @function onSubmit
         * @params formdata
         * @description used to edit sub organizer
         */
    const onSubmit = async (formdata) => {
        const data = new FormData()
        data.append('_method', 'PUT')
        data.append('name', formdata?.name)
        data.append('phone', formdata?.phone)
        data.append('email', formdata?.email)
        if (subOrganizerImage instanceof File) {
            data.append('profile_pic', subOrganizerImage)
        }
        if (formdata?.password) {
            data.append('password', formdata?.password)
        }
        data.append('country_id', formdata?.country_id)
        data.append('address', formdata?.address)
        data.append('gender', formdata?.gender)
        if (formdata?.password_confirmation) {
            data.append('password_confirmation', formdata?.password_confirmation)
        }
        try {
            setLoading(true)
            const result = await updateSubOrganizerService(props?.selectedItem?.id, data);
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                props?.fetchSubOrganizerList()
                handleClose()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    /**
         * @function handleClose
         * @params
         * @description used to close edit sub organizer modal
         */
    const handleClose = async () => {
        reset()
        setSubOrganizerImage()
        props.handleClose()
    }
    
    return <Modal show={props.show} onHide={handleClose} centered size='lg'>
        <LoadingOverlay
            active={loading}
            spinner={<Spinner animation="border" />}
        >
            <Modal.Header className='fs-24 fw-600 border-0' closeButton>
                Edit Sub-Organizer
            </Modal.Header>
            <Modal.Body className='d-flex flex-column pt-0 align-items-center'>
                <div className='w-100'>
                    <Row>
                        <input className='d-none' type='file' id="subOrganizerPhotoUpload" accept="image/*" onChange={(e) => {
                            if (e.target.files.length > 0) {
                                const file = e.target.files[0];
                                setSubOrganizerImage(file)
                            }
                        }} onClick={(e) => e.target.value = null} />
                        <Col lg={4}>
                            <p>Profile Image</p>
                            <label className='w-100' htmlFor="subOrganizerPhotoUpload">
                                {subOrganizerImage instanceof File
                                    ?
                                    <img role='button' className='w-100' src={URL.createObjectURL(subOrganizerImage)} alt={props?.selectedItem?.name} />
                                    :
                                    subOrganizerImage
                                        ?
                                        <img role='button' className='w-100' src={subOrganizerImage} alt={props?.selectedItem?.name} />
                                        :
                                        <div role='button' className='d-flex flex-column justify-content-center align-items-center bg-danger bg-opacity-10 py-5'>
                                            <Image />
                                            <span className='fs-18 fw-600 text-primary mb-3'>Add Profile Image</span>
                                        </div>
                                }
                            </label>
                        </Col>
                    </Row>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter name here"
                                {...register("name", {
                                    required: "Username is required"
                                })}
                            />
                            {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
                        </Form.Group>

                        <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Email Address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                {...register("email", {
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address"
                                    }
                                })}
                            />
                            {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
                        </Form.Group>
                                    
                        <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Phone number</Form.Label>
                            <InputGroup className='rounded'>
                                <InputGroup.Text className='p-0 border-0'>
                                    <Form.Select
                                        className='border-end-0'
                                        {...register("country_id")}
                                    >
                                        {countryList.map((item, index) => {
                                            return <option value={item?.id} key={index}>{item?.code}</option>
                                        })}
                                    </Form.Select>
                                </InputGroup.Text>
                                <Form.Control
                                    className='border-start-0 ps-0'
                                    type="tel"
                                    placeholder="Enter Phone number"
                                    {...register("phone", {
                                        required: "Phone Number is required",
                                        minLength: {
                                            value: 9,
                                            message: "Phone Number should be 9 digit minimum"
                                        },
                                        maxLength: {
                                            value: 11,
                                            message: "Phone Number should be 11 digit maximum"
                                        }
                                    })}
                                />
                            </InputGroup>
                            {errors.phone && <ErrorText>{errors.phone.message}</ErrorText>}
                        </Form.Group>

                        <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Address</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter Address"
                                {...register("address")}
                            />
                        </Form.Group>

                        <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Gender</Form.Label>
                            <Form.Select
                                {...register("gender")}
                            >
                                <option value='MALE'>Male</option>
                                <option value='FEMALE'>Female</option>
                            </Form.Select>
                        </Form.Group>

                        <Row>
                            <span className='fw-600 mb-2'>Create Password</span>
                            <Col xs={12} sm={6}>
                                <Form.Group className="mb-4">
                                    <Form.Label className='fs-14 fw-500'>Password*</Form.Label>
                                    <InputGroup className='rounded'>
                                        <Form.Control
                                            className='border-end-0'
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Enter password'
                                            {...register('password', {
                                                required: "Password is required"
                                            })}
                                        />
                                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowPassword}>{showPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                                    </InputGroup>
                                    {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Form.Group className="mb-4">
                                    <Form.Label className='fs-14 fw-500'>Confirm Password*</Form.Label>
                                    <InputGroup className='rounded'>
                                        <Form.Control
                                            className='border-end-0'
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            placeholder='Enter password'
                                            {...register('password_confirmation', {
                                                required: "Confirm Password is required",
                                            })}
                                        />
                                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowConfirmPassword}>{showConfirmPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                                    </InputGroup>
                                    {errors.password_confirmation && <ErrorText>{errors.password_confirmation.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className='d-flex justify-content-end align-items-end mt-4'>
                            <div>
                                <PrimaryButton className='fs-14 fw-500 px-5' disabled={loading} loading={loading} type="submit">Update</PrimaryButton>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </LoadingOverlay>
    </Modal>
};

export default EditSubOrganizerModal;